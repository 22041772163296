import React from 'react';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    NormalQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../../components/InnerPage';

const FortschreitendeKulturZitate = () => (
    <InnerPage title="Zitate – Fortschreitende Kultur" description="">
        <IntroHolder
            title="Zitate"
            subtitle="Fortschreitende Kultur"
            breadcrumbs={{
                greatgrandparent: 'Woran Bahá’í glauben',
                grandparent: 'Gott und der Mensch',
                parent: 'Fortschreitende Kultur',
                'current-item': 'Zitate',
            }}
        />
        <TwoColumns>
            <div id="content">
                <NormalQuote reference="Bahá’u’lláh, Ährenlese">
                    Der Mensch wurde erschaffen, eine ständig fortschreitende
                    Kultur voranzutragen. Der Allmächtige bezeugt Mir: Wie die
                    Tiere auf dem Felde zu leben, ist des Menschen unwürdig. Die
                    Tugenden, die seiner Würde anstehen, sind Geduld, Erbarmen,
                    Mitleid und Güte für alle Völker und Geschlechter der Erde.
                    Sprich: O Freunde! Trinkt euch satt aus diesem
                    kristallklaren Strom, der durch die himmlische Gnade dessen
                    dahinfließt, der der Herr aller Namen ist. Laßt in Meinem
                    Namen andere an seinen Wassern teilhaben, damit die Führer
                    der Menschen in allen Landen klar erkennen, aus welchem
                    Grund die Ewige Wahrheit offen­bart ist und warum sie selbst
                    erschaffen wurden.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Ährenlese">
                    Die Wohlfahrt der Menschheit, ihr Friede und ihre Sicherheit
                    sind unerreichbar, ehe nicht ihre Ein­heit fest begründet
                    ist. Diese Einheit kann so lange nicht zustande kommen, als
                    die Ratschläge, die die Feder des Höchsten offenbart hat,
                    unbeachtet bleiben.
                </NormalQuote>

                <NormalQuote reference="Shoghi Effendi, Die Weltordnung Bahá’u’lláhs">
                    Die langen Zeiten der Kindheit und der Minderjährigkeit,
                    welche die Menschheit zu durchschreiten hatte, sind in den
                    Hintergrund getreten. Die Menschheit erlebt jetzt die
                    Erregungen, die unabänderlich mit der stürmischsten Stufe
                    ihrer Entwicklung, dem Jünglingsalter, verbunden sind. In
                    dieser Zeit erreichen jugendliche Unbändigkeit und
                    Heftigkeit den Höhepunkt; sie müssen Schritt für Schritt von
                    der Ruhe, der Weisheit und der Vollendung abgelöst werden,
                    welche die Stufe des Mannesalters kennzeichnen. Dann wird
                    das Menschengeschlecht jene Gestalt der Reife erlangen, die
                    es befähigen wird, alle die Kräfte und Fähigkeiten zu
                    erwerben, von denen seine Entwicklung letztlich abhängt.
                </NormalQuote>

                <NormalQuote reference="Shoghi Effendi, Die Weltordnung Bahá’u’lláhs">
                    Die Vereinigung der ganzen Menschheit ist das Kennzeichen
                    der Stufe, der sich die menschliche Gesellschaft heute
                    nähert. Die Einheit der Familie, des Stammes, des
                    Stadtstaates und der Nation ist nacheinander in Angriff
                    genommen und völlig erreicht worden. Welteinheit ist das
                    Ziel, dem eine gequälte Menschheit zustrebt. Der Aufbau von
                    Nationalstaaten ist zu einem Ende gekommen. Die Anarchie,
                    die der nationalstaatlichen Souveränität anhaftet, nähert
                    sich heute einem Höhepunkt. Eine Welt, die zur Reife
                    heranwächst, muß diesen Fetisch aufgeben, die Einheit und
                    Ganzheit der menschlichen Beziehungen erkennen und ein für
                    allemal den Apparat aufrichten, der diesen Leitgrundsatz
                    ihres Daseins am besten zu verkörpern vermag.
                </NormalQuote>

                <NormalQuote reference="Shoghi Effendi, Die Weltordnung Bahá’u’lláhs">
                    Die Offenbarung Bahá’u’lláhs, deren höchstes Ziel es ist,
                    diese organische, geistige Einheit aller Nationen in ihrer
                    Gesamtheit zu vollenden, muß, wenn wir zu ihren
                    selbstverständlichen Folgerungen stehen, als Signal für den
                    Eintritt des gesamten Menschengeschlechts in den Zustand der
                    Mündigkeit betrachtet werden. […] Das Hervortreten einer
                    Weltgemeinschaft, das Bewußtsein des Weltbürgertums, die
                    Begründung einer Weltzivilisation und Weltkultur […] sollten
                    ihrer wahren Natur nach, was dieses planetarische Leben
                    anbelangt, als die äußersten Grenzen für die Organisation
                    der menschlichen Gesellschaft angesehen werden, wenngleich
                    der Mensch als Einzelwesen im Ergebnis dieser Vollendung
                    unbegrenzt weiter fortschreiten, sich weiter entwickeln wird
                    und muß.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Das Geheimnis göttlicher Kultur">
                    Gott hat uns Augen gegeben, damit wir uns in der Welt
                    umschauen und alles festhalten, was unsere Kultur und unsere
                    Lebenskunst voranbringt. Er hat uns Ohren gegeben, damit wir
                    zu unserem Nutzen auf die Weisheit der Gelehrten und
                    Philosophen hören und uns aufmachen, ihre Lehren zu fördern
                    und in die Tat umzusetzen. sinne und Fähigkeiten sind uns
                    verliehen worden, damit wir sie dem Dienst am allgemeinen
                    Wohl weihen, so daß wir, die wir uns durch
                    Wahrnehmungsvermögen und Verstand hoch über alle anderen
                    Lebensformen erheben, uns allezeit und in jeder Hinsicht,
                    bei wichtigen und bei nebensächlichen Anlässen, in normalen
                    oder außergewöhnlichen Verhältnissen, darum mühen, die ganze
                    Menschheit sicher in der unbezwingbaren Feste des Wissens zu
                    versammeln.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Das Geheimnis göttlicher Kultur">
                    Aufrichtigkeit ist der Grundstein des Glaubens. Das heißt,
                    ein religiöser Mensch muß seine persönlichen Wünsche
                    vergessen und danach streben, auf jede ihm mögliche Weise
                    und von ganzem Herzen dem öffentlichen Wohl zu dienen.
                    Andererseits ist es einem menschlichen Wesen nicht möglich,
                    sich von seinem eigenen, selbstsüchtigen Nutzen abzuwenden
                    und sein Wohl dem Wohl der Allgemeinheit zu opfern, es sei
                    denn durch wahren religiösen Glauben. Denn Eigenliebe ist in
                    jenen Klumpen Lehm, aus dem der Mensch gemacht ist,
                    hineingeknetet, und ohne die Aussicht auf eine ansehnliche
                    Belohnung wird keiner seinen handgreiflichen materiellen
                    Nutzen hintanstellen. Ein Mensch aber, der an Gott und Sein
                    Wort glaubt, wird um Gottes willen seinen eigenen Vorteil
                    und seine Behaglichkeit aufgeben und sich mit Herz und
                    Seele, aus freien Stücken, dem Allgemeinwohl weihen, weil er
                    die Verheißung und die Gewißheit hat, daß ihn im nächsten
                    Leben reicher Lohn erwartet, und weil ihm aller irdische
                    Nutzen nichts bedeutet im Vergleich zu der immerwährenden
                    Freude und Herrlichkeit künftiger Stufen seines Seins. »Ein
                    anderer aber ist unter den Menschen, der sein eigenes Selbst
                    verkauft aus Verlangen nach dem Wohlgefallen Gottes« (Qur’án
                    2:203).
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Briefe und Botschaften">
                    Wie ist so manches Kind noch jung an Jahren und doch schon
                    reif und sicher in seinem Urteil! Und wie ist so mancher
                    betagte Mensch unwissend und verwirrt! Denn Wachstum und
                    Entwicklung hängen von den Verstandeskräften und der
                    Vernunft eines Menschen ab, nicht von seinem Alter oder der
                    Dauer seiner Erdentage.
                </NormalQuote>

                <NormalQuote reference="Bahá’u’lláh, Botschaften aus Akka, 5:15">
                    Wissen gleicht den Flügeln für des Menschen Leben, einer
                    Leiter für seinen Aufstieg. Es ist jedermanns Pflicht, sich
                    Wissen zu erwerben. Jedoch sollten solche Wissenschaften
                    studiert werden, die den Völkern auf Erden nützen, nicht
                    solche, die mit Worten beginnen und mit Worten enden. Viel
                    verdanken fürwahr die Völker der Welt den Wissenschaftlern
                    und den Handwerkern. Dies bezeugt das Mutterbuch am Tage
                    Seiner Wiederkehr. Glücklich ist, wer hörende Ohren besitzt.
                    In der Tat, Wissen ist ein wahrer Schatz für den Menschen,
                    eine Quelle des Ruhmes, der Großmut, der Freude, der
                    Erhabenheit, des Frohsinns und der Heiterkeit. Also spricht
                    die Zunge der Größe in diesem Größten Gefängnis.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Briefe und Botschaften">
                    Die materielle Zivilisation ist wie das Glas um die Lampe,
                    die göttliche Kultur ist die Lampe selbst. Das Glas ohne
                    Licht ist dunkel. Die materielle Zivilisation ist wie der
                    Leib. Sei er auch noch so anmutig, elegant und schön, so ist
                    er dennoch tot. Die göttliche Kultur ist wie der Geist; der
                    Leib erhält sein Leben durch den Geist, sonst ist er ein
                    Leichnam. So ist es klar, daß die Menschenwelt den Odem des
                    Heiligen Geistes braucht. Ohne den Geist ist die
                    Menschenwelt leblos; ohne dieses Licht verbleibt die
                    Menschenwelt in tiefster Finsternis.
                </NormalQuote>

                <NormalQuote reference="‘Abdu’l-Bahá, Brief an Forel">
                    Jede höhere Stufe begreift die niedrigere und entdeckt deren
                    Wirklichkeit, aber die niedrigere weiß nichts von der
                    höheren und kann sie nicht begreifen, doch durch die Macht
                    seiner Vernunft, durch Beobachtung, durch seine
                    Einfühlungsgabe und durch die offenbarende Macht seines
                    Glaubens kann er Gott anerkennen und Gottes Gnadengaben
                    entdecken. Er wird gewiß, daß überzeugende (geistige)
                    Beweise das Sein jener unsichtbaren Wirklichkeit bestätigen,
                    auch wenn das Wesen Gottes dem Auge verborgen ist und Gottes
                    Sein nicht faßbar ist. Das Wesen Gottes, wie es in sich
                    selbst besteht, ist jedoch über jede Beschreibung erhaben.
                    So ist auch das Wesen des Äthers unbekannt; daß es ihn aber
                    gibt, wird aus seinen Wirkungen deutlich: Wärme, Licht und
                    Elektrizität sind seine Schwingungen. Durch diese
                    Schwingungen wird das Dasein des Äthers bewiesen. Und wenn
                    wir die Ausgießungen der göttlichen Gnade beobachten, werden
                    wir des göttlichen Seins gewiß.
                </NormalQuote>
            </div>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default FortschreitendeKulturZitate;
